<template>
  <v-row>
    <v-col col="4">
      <CardMiniatureHorizontalDemand
        :demand="demand"
        :property-id="demand.propertyId"
        :room-id="demand.roomId"
      />
    </v-col>
    <v-col cols="8">
      <DemandForm :demand="demand" :inputs-disabled="false" />
    </v-col>
  </v-row>
</template>

<script>
import DemandForm from '@/components/demands/DemandForm'
import CardMiniatureHorizontalDemand from '../../components/property/DemadPropertyCardMiniature'
export default {
  name: 'DemandDetail',
  props: {
    demand: {
      type: Object,
      required: true
    }
  },
  components: { CardMiniatureHorizontalDemand, DemandForm }
}
</script>

<style scoped>

</style>
