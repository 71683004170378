<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2" id="user-demand-nav">
        <v-list>
          <div
            v-for="(demand, index) in demands"
            :key="index">
            <v-list-item
              three-line
              link
              @click="showDemand(demand)"
              >
              <v-list-item-content>
                <v-list-item-title>{{demand.property.name}}</v-list-item-title>
                <v-list-item-subtitle>{{getLocalizedDate(demand.dateFrom)}} - {{getLocalizedDate(demand.dateTo)}}</v-list-item-subtitle>
                <v-list-item-subtitle >
                  <v-chip class="rounded-0" small :color="getColor(demand)">{{getFirmText(demand)}}</v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
        <v-btn
          @click="getDemands"
          v-if="demands.length < totalDemands"
        >
          Načíst další
        </v-btn>
      </v-col>
      <v-col cols="10">
        <DemandDetail
          v-if="demandDetail"
          :demand="demandDetail"
        />
        <v-card v-else-if="demands.length === 0 && !runningRequest">
          <h3>Žádné poptávky k zobrazení</h3>
        </v-card>
        <v-skeleton-loader v-else type="image" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import demandsOverviewHelpers from '@/mixins/local/demandsOverviewHelpers'
import { mapGetters } from 'vuex'
import DemandDetail from '@/views/user/DemandDetail'
export default {
  name: 'UserDemands',
  components: { DemandDetail },
  mixins: [demandsOverviewHelpers],
  data () {
    return {
      runningRequest: false,
      page: 1,
      itemsPerPage: 10,
      totalDemands: null,
      demands: [],
      demandDetail: null
    }
  },
  methods: {
    showDemand (demand) {
      this.demandDetail = false
      this.$nextTick(() => {
        this.demandDetail = demand
      })
    },
    getDemands () {
      this.runningRequest = true

      this.$http.get(`/users/${this.loggedUser.id}/demands`, {
        params: {
          page: this.page++,
          itemsPerPage: this.itemsPerPage
        }
      })
        .then(res => {
          this.demands = [...this.demands, ...res.data.demands]
          this.totalDemands = +res.data.total
        })
        .finally(() => {
          this.runningRequest = false
        })
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'userAuth/user'
    })
  },
  watch: {
    demands: {
      deep: true,
      handler (newVal, oldVal) {
        if (!oldVal.length && newVal.length) {
          this.demandDetail = newVal[0]
        }
      }
    }
  },
  created () {
    this.getDemands()
  }
}
</script>

<style scoped>
#user-demand-nav, #user-demand-nav>.v-list{
  background-color: #449cea;
  max-height: 90vh;
  overflow: hidden;
}
#user-demand-nav>.v-list{
  overflow: auto;
}
</style>
